//*** Add table :'table' if service name is different then table name
const attachmentsSubMenu = [
  {
    type: 'link',
    label: 'Attachment Types',
    link: 'attachment-types',
    service: 'codeattachmenttypes',
  },
  /* { type: 'link', label: 'Image View Types', service: '' }, */
];
const PrePlanSubMenu = [
  {
    type: 'link',
    label: 'Construction Types',
    link: 'pre-plan-construction-types',
    service: 'codepreplanconstructiontypes',
  },
  {
    type: 'link',
    label: 'Occupancy Types',
    link: 'pre-plan-occupancy-types',
    service: 'codepreplanoccupancytypes',
  },
  {
    type: 'link',
    label: 'Roof Types',
    link: 'pre-plan-roof-types',
    service: 'codepreplanrooftypes',
  },
];
export const CADSubMenu = [
  { type: 'link', label: 'Call Methods', service: 'codecadmethods' },

  { type: 'link', label: 'Designations', link: 'designations', service: 'codedesignation' },
  {
    type: 'link',
    label: 'Dispositions',
    link: 'dispositions',
    service: 'code-dispositions',
    table: 'codeDispositions',
  },
  { type: 'link', label: 'Divisions', link: 'divisions', service: 'codedivision' },

  { type: 'menu', label: 'PrePlan', submenu: PrePlanSubMenu },
  {
    type: 'link',
    label: 'Requested Actions',
    link: 'requested-actions ',
    service: 'codeactivitytypes',
  },
  {
    type: 'link',
    label: 'Resource Capability Types',
    link: 'capability-types',
    service: 'codecapabilitytypes',
  },

  {
    type: 'link',
    label: 'Resource Categories',
    link: 'resource-categories',
    service: 'coderesourcecategories',
  },
  {
    type: 'link',
    label: 'Unit Status',
    link: 'unit-actions',
    service: 'settings-unit-actions',
    table: 'codeUnitActions',
  },
  {
    type: 'link',
    label: 'Unit Status Modifier',
    link: 'unit-action-modifier',
    service: 'codeunitactionmodifier',
  },
  {
    type: 'link',
    label: 'Reason Towed',
    link: 'reason-towed',
    service: 'codereasontowed',
  },

  /* { type: 'link', label: 'ESNs', service: '' }, */
  /* { type: 'link', label: 'Event Types', service: '' }, */
  /* { type: 'link', label: 'Event Sub Types', service: '' }, */
];

const contactSubMenu = [
  {
    type: 'link',
    label: 'Contact Info Method Types',
    link: 'contact-info-method-types',
    service: 'codecontactinfomethodtypes',
  },
];

const featureSubMenu = [
  { type: 'link', label: 'Appearances', link: 'appearances', service: 'codeappearance' },
  { type: 'link', label: 'Blood Types', link: 'blood-types', service: 'codebloodtype' },
  { type: 'link', label: 'Builds', link: 'builds', service: 'codebuilds' },
  { type: 'link', label: 'Complexions', link: 'complexions', service: 'codecomplexions' },
  { type: 'link', label: 'Ethnicity', link: 'ethnicity', service: 'codeethnicity' },
  { type: 'link', label: 'Eye Colors', link: 'eye-colors', service: 'codeeyecolor' },
  { type: 'link', label: 'Eye Defects', link: 'eye-defects', service: 'codeeyedefect' },
  { type: 'link', label: 'Facial Hair', link: 'facial-hair', service: 'codefacialhair' },
  { type: 'link', label: 'Glasses', link: 'glasses', service: 'codeglasses' },
  { type: 'link', label: 'Hair Colors', link: 'hair-colors', service: 'codehaircolor' },
  { type: 'link', label: 'Hair Lengths', link: 'hair-lengths', service: 'codehairlength' },
  { type: 'link', label: 'Hair Styles', link: 'hair-styles', service: 'codehairstyle' },
  { type: 'link', label: 'Handed', link: 'handed', service: 'codehanded' },

  {
    type: 'link',
    label: 'Methods of Operation',
    link: 'methods-of-operation',
    service: 'codemethodofoperation',
  },
  {
    type: 'link',
    label: 'Speech Qualities',
    link: 'speech-qualities',
    service: 'codespeechquality',
  },
  { type: 'link', label: 'Talks', link: 'talks', service: 'codetalk' },
  { type: 'link', label: 'Teeth', link: 'teeth', service: 'codeteeth' },
];

const credentialsSubMenu = [
  { type: 'link', label: 'Classes', link: 'classes', service: 'codecredentialclasses' },
  { type: 'link', label: 'Types', link: 'types', service: 'codecredentialtypes' },
];
const cellCarrierSubMenu = [
  { type: 'link', label: 'Types', link: 'cell-carrier', service: 'codecellcarrier' },
];
const measureSubMenu = [
  { type: 'link', label: 'Units of Measure', link: 'units-of-measure', service: 'codemeasures' },
];

const itemsSubMenu = [
  { type: 'link', label: 'Categories', link: 'categories', service: 'codeitemcategory' },
  { type: 'link', label: 'Conditions', link: 'conditions', service: 'codeitemconditions' },
  {
    type: 'link',
    label: 'DEA Drug Classes',
    link: 'dea-drug-classes',
    service: 'codedeadrugclasses',
  },
  {
    type: 'link',
    label: 'Dispositions',
    link: 'item-dispositions',
    service: 'codeitemdisposition',
  },
  {
    type: 'link',
    label: 'Firearm Calibers',
    link: 'firearm-calibers',
    service: 'codefirearmcaliber',
  },
  {
    type: 'link',
    label: 'Firearm Categories',
    link: 'firearm-categories',
    service: 'codefirearmcategory',
  },
  {
    type: 'link',
    label: 'Identification Categories',
    link: 'identification-categories',
    service: 'codeitemidentificationcategory',
  },
  {
    type: 'link',
    label: 'Jewelry Categories',
    link: 'jewelry-categories',
    service: 'codejewelrycategory',
  },
  { type: 'link', label: 'Makes', link: 'makes', service: 'codeitemmakes' },
  { type: 'link', label: 'Materials', link: 'materials', service: 'codematerial' },
  { type: 'link', label: 'Models', link: 'models', service: 'codeitemmodels' },
  { type: 'link', label: 'Statuses', link: 'statuses', service: 'codeitemstatus' },
  { type: 'link', label: 'Stone Types', link: 'stone-types', service: 'codestonetype' },

  { type: 'link', label: 'Usages', link: 'usages', service: 'codeitemusage' },
];
const placeSubMenu = [
  { type: 'link', label: 'Types', link: 'place-types', service: 'codeplacetypes' },
];

const zonesSubMenu = [
  { type: 'link', label: 'Types', link: 'zone-types', service: 'codezonetypes' },
];

const vehicleSubMenu = [
  { type: 'link', label: 'Branders', link: 'vehicle-branders', service: 'codevehiclebranders' },
  { type: 'link', label: 'Brands', link: 'vehicle-brands', service: 'codevehiclebrands' },
  {
    type: 'link',
    label: 'Classifications',
    link: 'vehicle-classifications',
    service: 'codevehicleclassifications',
  },
  { type: 'link', label: 'Colors', link: 'vehicle-colors', service: 'codevehiclecolors' },
  {
    type: 'link',
    label: 'Commercials',
    link: 'vehicle-commercials',
    service: 'codevehiclecommercial',
  },

  {
    type: 'link',
    label: 'Garage Facilities',
    link: 'garage-facilities',
    service: 'codegaragefacilities',
  },
  { type: 'link', label: 'Makes', link: 'vehicle-makes', service: 'codevehiclemakes' },
  { type: 'link', label: 'Models', link: 'vehicle-models', service: 'codevehiclemodels' },
  {
    type: 'link',
    label: 'Make Models',
    link: 'make-models',
    service: 'code-vehicle-make-models',
    table: 'codeVehicleMakesModels',
  },
  { type: 'link', label: 'Plate Colors', link: 'plate-colors', service: 'codeplatecolors' },
  { type: 'link', label: 'Plate Details', link: 'plate-details', service: 'codeplatedetails' },
  { type: 'link', label: 'Plate Statuses', link: 'plate-statuses', service: 'codeplatestatuses' },
  { type: 'link', label: 'Plate Types', link: 'plate-types', service: 'codeplatetypes' },
  { type: 'link', label: 'Properties', link: 'vehicle-properties', service: 'codevehicleproperty' },
  { type: 'link', label: 'Styles', link: 'vehicle-styles', service: 'codevehiclestyles' },
  { type: 'link', label: 'Transmissions', link: 'transmissions', service: 'codetransmissions' },
];

const employmentSubMenu = [
  { type: 'link', label: 'Occupations', link: 'occupations', service: 'codeoccupations' },
  { type: 'link', label: 'Positions', link: 'positions', service: 'codepositions' },
  { type: 'link', label: 'Ranks', link: 'ranks', service: 'coderanks' },
  {
    type: 'link',
    label: 'Statuses',
    link: 'employment-statuses',
    service: 'codeemploymentstatuses',
  },
  {
    type: 'link',
    label: 'Termination Reasons',
    link: 'termination-reasons',
    service: 'codeterminationreasons',
  },
];

const PersonSubmenu = [
  {
    type: 'link',
    label: 'Associate Relationships',
    link: 'associate-relationships',
    service: 'codeassociaterelationships',
  },
  { type: 'link', label: 'Citizenships', link: 'citizenships', service: 'codecitizenships' },
  { type: 'link', label: 'Dangerous Types', link: 'dangerous-types', service: 'codedangeroustype' },
  { type: 'link', label: 'Death Causes', link: 'death-causes', service: 'codedeathcauses' },
  { type: 'link', label: 'Gangs', link: 'gangs', service: 'codegang' },
  { type: 'link', label: 'Languages', link: 'languages', service: 'codelanguages' },
  { type: 'link', label: 'Marital Status', link: 'marital-status', service: 'codemaritalstatus' },
  { type: 'link', label: 'Mark Locations', link: 'mark-locations', service: 'codemarklocation' },
  { type: 'link', label: 'Mark Type', link: 'marks', service: 'codemarks' },
  {
    type: 'link',
    label: 'Mugshot Types',
    link: 'code-mugshot-types',
    service: 'codemugshottypes',
  },
  { type: 'link', label: 'Name Prefixes', link: 'name-prefixes', service: 'codenameprefixes' },
  { type: 'link', label: 'Name Suffixes', link: 'name-suffixes', service: 'codenamesuffixes' },
  { type: 'link', label: 'Name Types', link: 'name-types', service: 'codepersonnametypes' },
  { type: 'link', label: 'Nationalities', link: 'nationalities', service: 'codenationalities' },
  { type: 'link', label: 'Races', link: 'races', service: 'coderace', table: 'codeRace' },
  { type: 'link', label: 'Residents', link: 'resident', service: 'coderesident' },
  { type: 'link', label: 'Sexes', link: 'sexes', service: 'codesex', table: 'codeSex' },
];

const locationSubMenu = [
  {
    type: 'link',
    label: 'Address Building Types',
    link: 'address-building-types',
    service: 'codeaddressbldgtypes',
  },
  {
    type: 'link',
    label: 'Address Categories',
    link: 'address-categories',
    service: 'codeaddresscategories',
  },
  {
    type: 'link',
    label: 'Address Floor Types',
    link: 'address-floor-types',
    service: 'codeaddressfloortypes',
  },
  {
    type: 'link',
    label: 'Address Party Relationships',
    link: 'address-party-relationships',
    service: 'codeaddresspartyrelationship',
  },
  {
    type: 'link',
    label: 'Address Unit Types',
    link: 'address-unit-types',
    service: 'codeaddressunittypes',
  },
  { type: 'link', label: 'Cities', link: 'cities', service: 'codecities' },
  {
    type: 'link',
    label: 'Coordinate Datum Types',
    link: 'coordinate-datum-types',
    service: 'codecoordinatedatumtypes',
  },
  { type: 'link', label: 'Counties', link: 'counties', service: 'codecounties' },
  { type: 'link', label: 'Countries', link: 'countries', service: 'codecountries' },
  { type: 'link', label: 'Garage Types', link: 'garage-types', service: 'codegaragetypes' },

  { type: 'link', label: 'States', link: 'states', service: 'codestates' },
  {
    type: 'link',
    label: 'Street Directions',
    link: 'street-directions',
    service: 'codestreetdirections',
  },
  { type: 'link', label: 'Street Types', link: 'street-types', service: 'codestreettypes' },
  {
    type: 'link',
    label: 'Structure Colors',
    link: 'structure-colors',
    service: 'codestructurecolors',
  },
  /* { type: 'link', label: 'Categories', service: '' }, */
];
const dnaSubMenu = [
  {
    type: 'link',
    label: 'Collection',
    link: 'code-dna-collection',
    service: 'codednacollection',
  },
  {
    type: 'link',
    label: 'Sample Method',
    link: 'code-dnasamplemethod',
    service: 'codednasamplemethod',
  },
  {
    type: 'link',
    label: 'Sample Reason',
    link: 'code-dna-sample-reason',
    service: 'codednasamplereason',
  },
  {
    type: 'link',
    label: 'Sample Type',
    link: 'code-dna-sample-type',
    service: 'codednasampletype',
  },
];
const storageSubmenu = [
  {
    type: 'link',
    label: 'Location',
    link: 'code-evidence-storage-location',
    service: 'codeevidencestoragelocation',
  },
  // {
  //   type: 'link',
  //   label: 'Sub Location',
  //   link: 'code-evidence-storage-sublocation',
  //   service: 'codeevidencestoragesublocation',
  //   codeCategory: 'codeevidencestoragelocation',
  // },
  {
    type: 'link',
    label: 'Container',
    link: 'code-evidence-storage-container',
    service: 'codeevidencestoragecontainer',
  },
  {
    type: 'link',
    label: 'Temporary Location',
    link: 'code-evidence-temporary-location',
    service: 'codeevidencetemporarylocation',
  },
];
const evidenceSubMenu = [
  {
    type: 'link',
    label: 'Category',
    link: 'code-evidence-category',
    service: 'codeevidencecategory',
  },
  {
    type: 'link',
    label: 'Custody Reason',
    link: 'code-evidence-custody-reason',
    service: 'codeevidencecustodyreason',
  },
  {
    type: 'link',
    label: 'Custody Status',
    link: 'code-evidencecustodystatus',
    service: 'codeevidencecustodystatus',
  },
  {
    type: 'link',
    label: 'Disposal Code',
    link: 'code-evidencedisposalcode',
    service: 'codeevidencedisposalcode',
  },
  {
    type: 'link',
    label: 'Final Disposition',
    link: 'code-evidence-final-disposition',
    service: 'codeevidencefinaldisposition',
  },
  {
    type: 'link',
    label: 'Required Action',
    link: 'code-evidence-required-action',
    service: 'codeevidencerequiredaction',
  },
  {
    type: 'link',
    label: 'Type',
    link: 'code-evidence-type',
    service: 'codeevidencetype',
  },
  { type: 'menu', label: 'Storage', submenu: storageSubmenu },
];
const firearmSubmenu = [
  {
    type: 'link',
    label: 'Caliber',
    link: 'code-firearm-caliber',
    service: 'codefirearmcaliber',
  },
  {
    type: 'link',
    label: 'Category',
    link: 'code-firearm-category',
    service: 'codefirearmcategory',
  },
];
const afterActionSubMenu = [
  {
    type: 'link',
    label: 'Conditions',
    link: 'code-conditions',
    service: 'codeconditions',
  },
  {
    type: 'link',
    label: 'Reasons',
    link: 'code-reasons',
    service: 'codereasons',
  },
  {
    type: 'link',
    label: 'Impairments',
    link: 'code-impairments',
    service: 'codeimpairments',
  },
  {
    type: 'link',
    label: 'Resistances',
    link: 'code-resistances',
    service: 'coderesistances',
  },
  {
    type: 'link',
    label: 'Threat Towards',
    link: 'code-threat-towards',
    service: 'codetreattowards',
  },
  {
    type: 'link',
    label: 'Force Types',
    link: 'code-force-types',
    service: 'codeforcetypes',
  },
  {
    type: 'link',
    label: 'Injuries',
    link: 'code-injuries',
    service: 'codeinjuries',
  },
  {
    type: 'link',
    label: 'Assignment Types',
    link: 'code-assignment-types',
    service: 'codeassignmenttypes',
  },
  {
    type: 'link',
    label: 'Weapons',
    link: 'code-weapons',
    service: 'codeweapons',
  },
  {
    type: 'link',
    label: 'Review Types',
    link: 'code-review-types',
    service: 'codereviewtypes',
  },
  {
    type: 'link',
    label: 'Recommended Actions',
    link: 'code-recommended-actions',
    service: 'coderecommendedactions',
  },
];
const incidentSubMenu = [
  {
    type: 'link',
    label: 'Categories',
    link: 'code-incident-categories',
    service: 'codeincidentcategories',
  },
  {
    type: 'link',
    label: 'Status',
    link: 'code-incident-status',
    service: 'codeincidentstatus',
  },

  {
    type: 'link',
    label: 'Event Types',
    link: 'code-incident-types',
    service: 'codeincidenttypes',
  },
  {
    type: 'link',
    label: 'Lighting',
    link: 'code-lighting',
    service: 'codelighting',
  },
  {
    type: 'link',
    label: 'Material',
    link: 'code-material',
    service: 'codematerial',
  },
  {
    type: 'link',
    label: 'Measures',
    link: 'code-measures',
    service: 'codemeasures',
  },
  {
    type: 'link',
    label: 'Method Of Operation',
    link: 'code-method-of-operation',
    service: 'codemethodofoperation',
  },
  {
    type: 'link',
    label: 'Narrative Types',
    link: 'code-narrative-types',
    service: 'codenarrativetypes',
  },
  {
    type: 'link',
    label: 'Victim Disposition',
    link: 'code-victim-disposition',
    service: 'codevictimdisposition',
  },
  {
    type: 'link',
    label: 'Victim Treatment',
    link: 'code-victim-treatment',
    service: 'codevictimtreatment',
  },
  {
    type: 'link',
    label: 'Weather',
    link: 'code-weather',
    service: 'codeweather',
  },
  {
    type: 'link',
    label: 'Lock Reasons',
    link: 'code-lock-reasons',
    service: 'codelockreasons',
  },
];
const warrantSubMenu = [
  {
    type: 'link',
    label: 'Classes',
    link: 'code-warrant-classes',
    service: 'codewarrantclasses',
  },
  {
    type: 'link',
    label: 'Dispositions',
    link: 'code-warrant-dispositions',
    service: 'codewarrantdispositions',
  },

  // {
  //   type: 'link',
  //   label: 'Party Types',
  //   link: 'code-warrant-party-types',
  //   service: 'codewarrantpartytypes',
  // },
  // {
  //   type: 'link',
  //   label: 'Types',
  //   link: 'code-warrant-types',
  //   service: 'codewarranttypes',
  // },
  {
    type: 'link',
    label: 'Bond Types',
    link: 'code-bond-types',
    service: 'codebondtypes',
  },
];
const rmsSubMenu = [
  { type: 'menu', label: 'DNA', submenu: dnaSubMenu },
  { type: 'menu', label: 'Evidence', submenu: evidenceSubMenu },
  { type: 'menu', label: 'Firearm', submenu: firearmSubmenu },
  { type: 'menu', label: 'After Action Report', submenu: afterActionSubMenu },
  { type: 'menu', label: 'Incident', submenu: incidentSubMenu },
  { type: 'menu', label: 'Warrant', submenu: warrantSubMenu },
  {
    type: 'link',
    label: 'Statutes',
    link: 'statutes',
    service: 'settings-ptsStatutes',
    table: 'ptsStatute',
  },
  {
    type: 'link',
    label: 'Tattoo Location',
    link: 'code-tattoo-location',
    service: 'codetattoolocation',
  },
  // {
  //   type: 'link',
  //   label: 'Capability Types',
  //   link: 'code-capability-types',
  //   service: 'codecapabilitytypes',
  // },
  {
    type: 'link',
    label: 'Custody Options',
    link: 'code-custody-options',
    service: 'codecustodyoptions',
  },

  {
    type: 'link',
    label: 'Negligent Manslaughter',
    link: 'code-negligent-manslaughter',
    service: 'codenegligentmanslaughter',
  },

  {
    type: 'link',
    label: 'Property Description',
    link: 'code-property-description',
    service: 'codepropertydescription',
  },
  {
    type: 'link',
    label: 'Resource Categories',
    link: 'code-resource-categories',
    service: 'coderesourcecategories',
  },

  {
    type: 'link',
    label: 'Subject Involvement',
    link: 'code-subject-involvement',
    service: 'codesubjectinvolvement',
  },
  {
    type: 'link',
    label: 'Subject MO',
    link: 'code-subject-mo',
    service: 'codesubjectmo',
  },
  {
    type: 'link',
    label: 'Subject Status',
    link: 'code-subject-status',
    service: 'codesubjectstatus',
  },
  {
    type: 'link',
    label: 'Subject Weapon',
    link: 'code-subject-weapon',
    service: 'codesubjectweapon',
  },

  {
    type: 'link',
    label: 'Zones',
    link: 'code-zones',
    service: 'codezones',
  },
  {
    type: 'link',
    label: 'Tag Types',
    link: 'code-Tag-Types',
    service: 'codetagtypes',
  },
];

// const RMSMenus = [...GlobalCodes, ...rmsSubMenu];
// const CadMenus = [...GlobalCodes, ...CADSubMenu];
export const rmsSubMenuSorted = rmsSubMenu.sort((a, b) => a.label.localeCompare(b.label));

const nibrsSubMenu = [
  {
    type: 'link',
    label: 'IBR Status',
    link: 'ibr-status',
    service: 'codeibrstatus',
    table: 'codeIBRStatus',
  },
  {
    type: 'link',
    label: 'Arrest Categories',
    link: 'code-arrest-categories',
    service: 'codearrestcategories',
  },
  {
    type: 'link',
    label: 'Arrestee Armed With',
    link: 'code-arrestee-armed-with',
    service: 'codearresteearmed',
    table: 'codeArresteeArmed',
  },
  {
    type: 'link',
    label: 'Arrestee Juvenile Disposition',
    link: 'code-arrestee-juvenile-disposition',
    service: 'codejuveniledisposition',
  },
  {
    type: 'link',
    label: 'Arrestee Subject Count',
    link: 'code-arrestee-subject-count',
    service: 'codearresteesegment',
  },
  {
    type: 'link',
    label: 'Criminal Activity Category',
    link: 'code-criminal-activities-category',
    service: 'codecriminalactivities',
    table: 'codeCriminalActivities',
  },

  {
    type: 'link',
    label: 'Substance Category',
    link: 'code-substance-category',
    service: 'codesubstancecategory',
    table: 'codeSubstanceCategory',
  },
  {
    type: 'link',
    label: 'Enforcement Official Activity Category',
    link: 'code-enforcement-Official-activity-category',
    service: 'codeofficeractivity',
  },
  {
    type: 'link',
    label: 'Enforcement Official Assignment Category',
    link: 'code-enforcement-official-assignment-category',
    service: 'codeofficerassignment',
  },
  {
    type: 'link',
    label: 'Code Force Category',
    link: 'code-force-category',
    service: 'codeforcecategory',
    table: 'codeForceCategory',
  },
  {
    type: 'link',
    label: 'Incident Exceptional Clearance',
    link: 'code-incident-exceptional-clearance',
    service: 'codeibrexceptionalclearance',
    table: 'codeIBRExceptionalClearance',
  },
  {
    type: 'link',
    label: 'Code Injury Category',
    link: 'code-injury-category',
    service: 'codeinjurycategory',
    table: 'codeInjuryCategory',
  },
  {
    type: 'link',
    label: 'Item Category NIBRS Property Category',
    link: 'code-item-category-nibrs-property-category',
    service: 'codepropertycategory',
    table: 'codePropertyCategory',
  },
  {
    type: 'link',
    label: 'Item Status',
    link: 'code-item-status',
    service: 'codepropertylosstype',
    table: 'codePropertyLossType',
  },
  {
    type: 'link',
    label: 'Location Categories',
    link: 'code-location-categories',
    service: 'codelocationcategories',
    table: 'codeLocationCategories',
  },
  {
    type: 'link',
    label: 'Method Of Entry',
    link: 'code-method-of-entry',
    service: 'codemethodofentry',
  },
  {
    type: 'link',
    label: 'Offense Factor Bias Motivation Code',
    link: 'code-offense-factor-bias-motivation-code',
    service: 'codebiasmotivations',
    table: 'codeBiasMotivations',
  },
  {
    type: 'link',
    label: 'Code Offense Factor',
    link: 'code-offense-factor',
    service: 'codeoffensefactor',
    table: 'codeOffenseFactor',
  },
  {
    type: 'link',
    label: 'Person Ethnicity',
    link: 'code-person-ethnicity',
    service: 'codeethnicity',
  },
  {
    type: 'link',
    label: 'Code Person Race NDEX',
    link: 'code-person-race-ndex',
    service: 'coderace',
    table: 'codeRace',
  },
  {
    type: 'link',
    label: 'Person Resident',
    link: 'code-person-resident',
    service: 'coderesident',
  },
  {
    type: 'link',
    label: 'Code Person Sex',
    link: 'code-person-sex',
    service: 'codesex',
    table: 'codeSex',
  },
  {
    type: 'link',
    label: 'Substance Unit',
    link: 'code-substance-unit',
    service: 'codedrugmeasurements',
    table: 'codeDrugMeasurements',
  },
  {
    type: 'link',
    label: 'Code Victim Aggravated Assault Homicide Factor',
    link: 'code-victim-aggravated-assault-homicide-factor',
    service: 'codeaggassaulttype',
    table: 'codeAggAssaultType',
  },
  {
    type: 'link',
    label: 'Code Victim Category',
    link: 'code-victim-category',
    service: 'codevictimcategory',
    table: 'codeVictimCategory',
  },
  {
    type: 'link',
    label: 'Victim Justifiable Homicide Factor',
    link: 'code-victim-justifiable-homicide-factor',
    service: 'codejustifiablehomicide',
  },
  {
    type: 'link',
    label: 'Code Victim To Subject Relationship',
    link: 'code-victim-to-subject-relationship',
    service: 'codevictimsubjectrelationship',
    table: 'codeVictimSubjectRelationship',
  },
];

export const nibrsSubMenuSorted = nibrsSubMenu.sort((a, b) => a.label.localeCompare(b.label));
const alertSubmenu = [
  { type: 'link', label: 'Alert Types', link: 'alert-types', service: 'codealerttypes' },
];

const PersonSubmenuTwo = [];

const partyRelationSubMenu = [
  {
    type: 'link',
    label: 'Party Relationship',
    link: 'party-relationships',
    service: 'codepartyrelationship',
    table: 'codePartyRelationship',
  },
];

const menu = [
  { type: 'menu', label: 'Alert', submenu: alertSubmenu },
  { type: 'menu', label: 'Attachment', submenu: attachmentsSubMenu },

  { type: 'divider' },
  { type: 'menu', label: 'Contact', submenu: contactSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Credentials', submenu: credentialsSubMenu },
  { type: 'menu', label: 'Cell Carrier', submenu: cellCarrierSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Employment', submenu: employmentSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Feature', submenu: featureSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Items', submenu: itemsSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Location', submenu: locationSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Measure', submenu: measureSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Party Relationship', submenu: partyRelationSubMenu },
  { type: 'divider' },

  { type: 'menu', label: 'Person', submenu: PersonSubmenu },
  { type: 'divider' },
  { type: 'menu', label: 'Vehicle', submenu: vehicleSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Place', submenu: placeSubMenu },
  { type: 'divider' },

  { type: 'menu', label: 'Zones', submenu: zonesSubMenu },
];
export const allMenu = [...menu, ...CADSubMenu, ...rmsSubMenu, ...nibrsSubMenu];
export default menu;
