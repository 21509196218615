/**
 * Component will contain all dialogs that could be displayed. Currently it is dialog for Adding Page only.
 */
import React from 'react';
import { connect } from 'react-redux';
import GroupEdit from '../Settings/PagingSetup/GroupEdit';
import RecipientEdit from '../Settings/PagingSetup/RecipientEdit';
import TemplateEdit from '../Settings/PagingSetup/TemplateEdit';
import AddSop from './AddSOP';
import AddSecUserDialog from './AddSecUserDialog';
import RemoveSecUserDialog from './RemoveSecUserDialog';
import OptionsDialog from './OptionsDialog';
import AddSecRoleDialog from './AddSecRoleDialog';
import AddSecProfileDialog from './AddSecProfileDialog';
import EditSecRoleDialog from './EditSecRoleDialog';
import EditSecProfileDialog from './EditSecProfileDialog';
import ImportEsns from 'Settings/GeofenceDesigner/ImportEsns';
import ImportZones from 'Settings/GeofenceDesigner/ImportZones';
import AddCitationFile from './AddCitationFile';
import AddAgencyUnitSelection from './AddAgencyUnitSelection';
import EditAgency from './Agencies/EditAgency';
import ContactMethodDialog from './ContactMethodDialog';
import PlaceEmployeeDialog from './PlaceEmployeeDialog';
import VehicleRegDialog from './VehicleRegDialog';
import EditPlaceAddressDialog from './EditPlaceAddressDialog';
import AddCitationCourt from './AddCitationCourt';
import AddEditClearanceLevel from './AddEditClearanceLevel';
import AddEditEventTypeInitials from './AddEditEventTypeInitials';
import DefaultEmails from './DefalutEmails';
import AddEventSubType from './AddEventSubType';
import MergeCities from './MergeCities/MergeCities';
import DeleteAgency from './Agencies/DeleteAgency';
import NIBRSOffenceCodeDialog from './NIBRSOffenceCodeDialog';
import RotationSetupDialog from './RotationSetupDialog';
import AddEditCodeCategoriesDialog from './AddEditCodeCategoriesDialog';
function Dialogs(props) {
  const { isAuthenticated, dialogs } = props;
  const {
    editPageGroup,
    editPageRecipient,
    editPageTemplate,
    editSop,
    addSecUser,
    removeSecUser,
    optionsDialog,
    addEditClearanceLevel,
    addSecRole,
    addSecProfile,
    editSecRole,
    editSecProfile,
    importZones,
    importEsns,
    addCitationFile,
    addAgencyUnitSelection,
    editAgency,
    deleteAgency,
    editContactMethod,
    placeEmployment,
    editVehicleReg,
    editPlaceAddress,
    addCitationCourt,
    addEditEventTypeInitials,
    defaultEmails,
    addEventSubTypes,
    mergeCities,
    editNibrsOffence,
    addRotation,
    addEditCodeCategories,
  } = dialogs;

  return (
    <>
      {isAuthenticated && (
        <>
          {editPageGroup !== null && <GroupEdit ptsGroupID={editPageGroup} />}
          {editPageRecipient !== null && <RecipientEdit ptsRecipientID={editPageRecipient} />}
          {editPageTemplate !== null && <TemplateEdit ROWGUID={editPageTemplate} />}
          {Boolean(editSop?.ptsSOPID || false) && (
            <AddSop ptsSOPID={editSop.ptsSOPID} ptsParentID={editSop.ptsParentID} />
          )}
          {addSecUser !== null && <AddSecUserDialog data={addSecUser} />}
          {removeSecUser !== null && <RemoveSecUserDialog data={removeSecUser} />}
          {optionsDialog !== null && <OptionsDialog data={optionsDialog} />}
          {addEditClearanceLevel !== null && <AddEditClearanceLevel data={addEditClearanceLevel} />}
          {addSecRole !== null && <AddSecRoleDialog data={addSecRole} />}
          {editSecRole !== null && <EditSecRoleDialog groupId={editSecRole} />}
          {addSecProfile !== null && <AddSecProfileDialog data={addSecProfile} />}
          {editSecProfile !== null && <EditSecProfileDialog profileId={editSecProfile} />}
          {editAgency !== null && (
            <EditAgency
              AgencyID={editAgency.AgencyID}
              Description={editAgency.Description}
              mode={editAgency.mode}
            />
          )}
          {deleteAgency !== null && <DeleteAgency AgencyID={deleteAgency} />}
          {importEsns !== null && <ImportEsns options={importEsns} />}
          {importZones !== null && <ImportZones options={importZones} />}
          {addCitationFile !== null && <AddCitationFile data={addCitationFile} />}
          {addCitationCourt !== null && <AddCitationCourt data={addCitationCourt} />}
          {addRotation !== null && <RotationSetupDialog data={addRotation} />}
          {addAgencyUnitSelection !== null && (
            <AddAgencyUnitSelection data={addAgencyUnitSelection} />
          )}
          {editContactMethod !== null && <ContactMethodDialog data={editContactMethod} />}
          {placeEmployment !== null && <PlaceEmployeeDialog data={placeEmployment} />}
          {editVehicleReg !== null && <VehicleRegDialog vehicleData={editVehicleReg} />}
          {editPlaceAddress !== null && (
            <EditPlaceAddressDialog
              ptsAddressID={editPlaceAddress.ptsAddressID}
              IsPrimary={editPlaceAddress.IsPrimary}
              ptsPlaceID={editPlaceAddress.ptsPlaceID}
            />
          )}
          {addEditEventTypeInitials !== null && (
            <AddEditEventTypeInitials data={addEditEventTypeInitials} />
          )}
          {defaultEmails !== null && <DefaultEmails data={defaultEmails} />}
          {addEventSubTypes !== null && (
            <AddEventSubType
              data={addEventSubTypes?.data || {}}
              type={addEventSubTypes.type || ''}
            />
          )}
          {mergeCities && <MergeCities data={mergeCities.data} rows={mergeCities.rows} />}
          {editNibrsOffence && <NIBRSOffenceCodeDialog data={editNibrsOffence} />}
          {addEditCodeCategories && <AddEditCodeCategoriesDialog data={addEditCodeCategories} />}
        </>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    dialogs: state.dialogs,
  };
};

export default connect(mapStateToProps)(Dialogs);
